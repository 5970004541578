import { Button, Control, Field, Input, Label, Notification } from 'rbx';
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';

function LoginForm({ onSuccess }) {
  const auth = useAuth();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [password, setPassword] = useState(null);
  const [usePassword, setUsePassword] = useState(false);

  async function submitHandler(e) {
    e.preventDefault();
    const trimEmail = email.trim();
    setIsDisabled(true);

    if (!trimEmail.endsWith('@resumedone.io')) {
      setIsDisabled(false);
      setError('Your email must end with `@resumedone.io`');
      return;
    }

    try {
      const response = await auth.login(trimEmail, password);
      if (response?.link) {
        window?.location?.assign(response?.link);
      }
      setError(null);
      onSuccess(true);
    } catch (e) {
      setError(e.message);
      setIsDisabled(false);
    }
  }

  const changeHandler = e => setEmail(e.target.value);
  const handlePasswordChange = e => setPassword(e?.target?.value);

  return (
    <form onSubmit={submitHandler}>
      <Field>
        <Label>Admin email</Label>
        <Control>
          <Input
            type="text"
            placeholder="you@resumedone.io"
            required
            onChange={changeHandler}
          />
        </Control>
        {!!usePassword && (
          <>
            <Label>Password</Label>
            <Control>
              <Input
                type="password"
                placeholder="Password"
                required
                onChange={handlePasswordChange}
              />
            </Control>
          </>
        )}
        <br />
        <Control>
          <Button type="submit" color="primary" disabled={isDisabled}>
            {!usePassword ? 'Request link' : 'Login'}
          </Button>
        </Control>
        <div
          style={{
            color: '#00b89c',
            margin: '10px 0',
            cursor: 'pointer',
            fontSize: '16px',
          }}
          onClick={() => setUsePassword(!usePassword)}
        >
          {usePassword ? 'Use Link' : 'Use Passward'}
        </div>
      </Field>
      {error && <Notification color="danger">{error}</Notification>}
    </form>
  );
}

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  onSuccess: () => {},
};

export default LoginForm;
